import React from "react";
import timelines from "../../data/timeline.json";
import { ReactComponent as Cloud } from "../../assets/images/cloud.svg";
import { ReactComponent as Rocket } from "../../assets/images/rocket.svg";
import "./timeline.scss";
import { matchAndReplace } from "../../helpers/helpers";

function Timeline() {
  return (
    <div className="timeline-section pt-5 min-h-screen flex relative flex-col gap-5 overflow-hidden pb-[15em] sm:pb-[20em] px-5 sm:!px-16 ">
      <h2 className="heading">
        Into the <br />
        Time <s className="opacity-50">travel</s> line
      </h2>
      <div className="timeline__container mt-16 sm:px-10 max-w-[1000px] mx-auto relative">
        <Rocket className="rocket absolute -right-[16px] sm:right-[20px] md:right-[22px] lg:right-[15px] xl:right-[12px] z-10 -top-[75px] sm:-top-[7%] md:-top-[120px] w-[12%] sm:w-[6%]  rotate-180" />
        <span>
          {timelines.map((timeline, i) => {
            return (
              <div
                className={`timeline__item timeline__item--${(i+1)%2 === 0 ? 'even' : 'odd' } [&:last-child]:after:w-[20%] [&:last-child]:after:h-full [&:last-child]:after:border-dashed [&:last-child]:after:absolute [&:last-child]:after:left-[30%]  [&:last-child]:after:bottom-[calc(-100%_-_15px)] sm:[&:last-child]:after:bottom-[calc(-94%_-_10px)] md:[&:last-child]:after:bottom-[calc(-91%)] lg:[&:last-child]:after:bottom-[calc(-93%)]  [&:last-child]:after:border-secondary [&:last-child]:after:border-4 [&:last-child]:after:border-r-0 [&:last-child]:after:border-b-0 [&:last-child]:after:rounded-tl-[200px]`}
                key={i}
              >
                <div className="timeline__item__year">{timeline.year}</div>
                <div className="timeline__item__content flex flex-col w-full sm:w-[80%] lg:w-[40%] bg-translucent rounded-[5px] backdrop-blur-[15px] p-2 sm:p-5 gap-0">
                  <h4 className="timeline__item__title font-medium text-lg sm:text-xl">
                    {timeline.title}
                  </h4>
                  <a
                    href={timeline.company.url || "#"}
                    rel="noreferrer"
                    target="_blank"
                    className="w-fit mb-3"
                  >
                    <h5 className="timeline__item__company text-base sm:text-lg font-normal text-ternary">
                      {timeline.company.name}
                    </h5>
                  </a>
                  {/* short description is returned from this function */}
                  {matchAndReplace(
                    timeline.short_desc,
                    "p",
                    "timeline__item__description"
                  )}
                  
                  {timeline.points?(<ul className="text-sm pl-4 list-disc mb-2">{timeline.points.map((point,index)=><li key={index} className="my-1">{point}</li>)}</ul>):""}
                  <p className="text-base font-normal">
                    <b className="text-secondary font-normal">
                      {timeline.batch ? "Batch" : "Stack"}:{" "}
                    </b>
                    {timeline.batch
                      ? timeline.batch
                      : timeline.stack.join(", ")}
                  </p>
                </div>
              </div>
            );
          })}
        </span>
      </div>
      <Cloud className="absolute w-[200%] -left-[50%] -bottom-[6%]" />
      <div className="footer absolute bottom-0 w-full left-0 text-center text-primary font-semibold p-3 flex justify-center">
        {/* Designed & Developed By Arv */}
        <a className="w-fit" target='_blank' href='https://webarv.com' rel="noreferrer">
                    <div
                        title="webarv"
                        className="relative w-fit flex opacity-80 hover:opacity-100 cursor-pointer gap-1 items-center  group"
                    >
                        <span className="text-gray-900">developed by</span>
                        <img src="/images/webarv.svg" alt="webarv" className="w-6 h-[25px]" />
                        <span
                            className="-right-[6ch] text-[#00a99d] absolute opacity-0 group-hover:opacity-100 transform translate-x-[-10px] group-hover:translate-x-0 transition-all duration-300"
                        >
                            webarv
                        </span>
                    </div>
            </a>
      </div>
    </div>
  );
}

export default Timeline;
